import { createTheme } from "@mui/material/styles";

const PrimaryTheme = createTheme({
    palette: {
        primary: {
            main: "#3457D5",
        },
    },
});

export { PrimaryTheme };
