import {
    FETCH_USERNAME,
    FETCH_PATIENT_LABEL_VALUE,
    CLEAR_CREDENTIALS,
} from "./constants/index";

const INITIAL_STATE = {
    username: localStorage.getItem("username"),
    patientLabelValue: localStorage.getItem("patientlabelvalue"),
};

export default (state = INITIAL_STATE, action: { type: any; payload: any }) => {
    switch (action.type) {
        case FETCH_USERNAME:
            return {
                ...state,
                username: action.payload,
            };
        case FETCH_PATIENT_LABEL_VALUE:
            return {
                ...state,
                patientLabelValue: action.payload,
            };
        case CLEAR_CREDENTIALS:
            return INITIAL_STATE;
        default:
            return state;
    }
};
