import { produce } from 'immer';
import { BusyIndicator, EwUiAction } from "../../utilities/types";

// INITIAL STATE
export const initialBusyState: BusyIndicator = {
    isBusy: false,
    message: undefined,
    error: undefined
};

// ACTIONS
export const loading = (message?: string) => EwUiAction(
    'sandbox/busy/loading', message);

export const done = (message?: string) => EwUiAction(
    'sandbox/busy/done', message);

export const failure = (message?: string, error?: Error) => EwUiAction(
    'sandbox/busy/failure', { message, error });

export type Action = ReturnType<
    typeof loading
    | typeof done
    | typeof failure>;


// REDUCER
const BusyIndicatorReducer =
    (state = initialBusyState, action: Action): BusyIndicator => {
        let updatedState = state;
        switch (action.type) {
            case 'sandbox/busy/loading':
                updatedState = produce(state, draft => {
                    draft.isBusy = true;
                    draft.message = action.payload;
                    draft.error = undefined;
                });
                return updatedState;
            case 'sandbox/busy/done':
                updatedState = produce(state, draft => {
                    draft.isBusy = false;
                    draft.message = action.payload;
                    draft.error = undefined;
                });
                return updatedState;
            case 'sandbox/busy/failure':
                updatedState = produce(state, draft => {
                    draft.isBusy = false;
                    draft.message = action.payload.message;
                    draft.error = action.payload.error;
                });
                return updatedState;
            default:
                return updatedState;
        }
    };

export default BusyIndicatorReducer;
