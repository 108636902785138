import { produce } from 'immer';
import { AuthState, EwUiAction } from '../../utilities/types';

// INITIAL STATE
export const initialAuthState: AuthState = {
    isAuthenticated: false,
    userName: null,
    sessionExpiresOn: null,

    token: undefined,
    tokenExpiresOn: undefined
};

// ACTIONS
export const login = (state: AuthState) => EwUiAction(
    'sandbox/auth/login', state);

export const logout = () => EwUiAction(
    'sandbox/auth/logout');

export const updateToken = (token?: string, tokenExpiresOn?: string) => EwUiAction(
    'sandbox/auth/updateToken', { token, tokenExpiresOn });

export type Action = ReturnType<
    typeof login
    | typeof logout
    | typeof updateToken>;

// REDUCER
const AuthReducer = (state = initialAuthState, action: Action) => {
    switch (action.type) {
        case 'sandbox/auth/login':
            return produce(state, draft => {
                Object.assign(draft, action.payload);
            });
        case 'sandbox/auth/logout':
            return produce(state, draft => {
                Object.assign(draft, initialAuthState);
            });
        case 'sandbox/auth/updateToken':
            return produce(state, draft => {
                draft.token = action.payload.token;
                draft.tokenExpiresOn = action.payload.tokenExpiresOn;
            });
        default:
            return state;
    }
};

export default AuthReducer;
