export function EwUiAction<T extends string>(type: T): {
    type: T;
};

export function EwUiAction<T extends string, P extends any>(type: T, payload: P): {
    type: T;
    payload: P;
};

export function EwUiAction(type: string, payload?: any) {
    return { type, payload };
}

export interface AuthState {
    isAuthenticated: boolean;
    userName: string | null;
    sessionExpiresOn: string | null;

    token?: string | null;
    tokenExpiresOn?: string | null;
}

export interface EwUserDetail {
    userName: string | null;
    firstName: string | null;
    lastName: string | null;
    accountId?: number;
    accountName?: string;
    roleId?: number;
    localeId?: number;
    localeKey?: string | null;
}

export interface BusyIndicator {
    isBusy: boolean;
    message?: string;
    error?: Error | null;
}
