import {
    GET_MEDICATION_INITIALIZE,
    GET_MEDICATION_SUCCESS,
    GET_MEDICATION_FAILED,
    GET_ALLERGIES_INITIALIZE,
    GET_ALLERGIES_SUCCESS,
    GET_ALLERGIES_FAILED,
    GET_HOSPITALIZATION_INITIALIZE,
    GET_HOSPITALIZATION_SUCCESS,
    GET_HOSPITALIZATION_FAILED,
    GET_VACCINATION_INITIALIZE,
    GET_VACCINATION_SUCCESS,
    GET_VACCINATION_FAILED,
    GET_SURGERY_IMPLANTS_INITIALIZE,
    GET_SURGERY_IMPLANTS_FAILED,
    GET_SURGERY_IMPLANTS_SUCCESS,
    GET_SOCIAL_BEHAVIOUR_SUCCESS,
    GET_SOCIAL_BEHAVIOUR_FAILED,
} from "../constants";

const initialState = {
    loading: false,
    success: false,
    message: "",
    medicationList: [],
    allergiesList: [],
    hospitalizationList: [],
    vaccinationList: [],
    surgeryImplantsList: [],
    socialBehaviourList: [],
};

const AssessmentDetailsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_MEDICATION_INITIALIZE:
            return {
                ...state,
                loading: true,
                medicationList: [],
            };
        case GET_MEDICATION_SUCCESS:
        case GET_MEDICATION_FAILED:
            return {
                ...state,
                loading: false,
                medicationList: action.payload,
            };
        case GET_ALLERGIES_INITIALIZE:
            return {
                ...state,
                loading: false,
                allergiesList: [],
            };

        case GET_ALLERGIES_SUCCESS:
        case GET_ALLERGIES_FAILED:
            return {
                ...state,
                loading: false,
                allergiesList: action.payload,
            };
        case GET_HOSPITALIZATION_INITIALIZE:
            return {
                ...state,
                loading: false,
                hospitalizationList: [],
            };
        case GET_HOSPITALIZATION_SUCCESS:
        case GET_HOSPITALIZATION_FAILED:
            return {
                ...state,
                loading: false,
                hospitalizationList: action.payload,
            };
        case GET_VACCINATION_INITIALIZE:
            return {
                ...state,
                loading: false,
                vaccinationList: [],
            };
        case GET_VACCINATION_SUCCESS:
        case GET_VACCINATION_FAILED:
            return {
                ...state,
                loading: false,
                vaccinationList: action.payload,
            };
        case GET_SURGERY_IMPLANTS_INITIALIZE:
            return {
                ...state,
                loading: true,
                surgeryImplantsList: [],
            };
        case GET_SURGERY_IMPLANTS_FAILED:
        case GET_SURGERY_IMPLANTS_SUCCESS:
            return {
                ...state,
                loading: false,
                surgeryImplantsList: action.payload,
            };
        case GET_SURGERY_IMPLANTS_INITIALIZE:
            return {
                ...state,
                socialBehaviourList: [],
                loading: true,
            };
        case GET_SOCIAL_BEHAVIOUR_SUCCESS:
        case GET_SOCIAL_BEHAVIOUR_FAILED:
            return {
                ...state,
                loading: false,
                socialBehaviourList: action.payload,
            };
        default:
            return { ...state };
    }
};

export default AssessmentDetailsReducer;
