import { produce } from 'immer';
import { EwUserDetail, EwUiAction } from "../../utilities/types";

// INITIAL STATE
export const initialUserState: EwUserDetail = {
    userName: null,
    firstName: null,
    lastName: null,
    accountId: undefined,
    accountName: undefined,
    roleId: undefined,
    localeId: undefined
};

// ACTIONS
export const setUser = (userDetail: EwUserDetail) => EwUiAction(
    'sandbox/user/setUser', userDetail);

export const getUser = () => EwUiAction(
    'sandbox/user/getUser');

export const setLocale = (localeKey: string) => EwUiAction(
    'sandbox/user/setLocale', { localeKey });

export type Action = ReturnType<
    typeof setUser
    | typeof getUser
    | typeof setLocale>;


// REDUCER
const UserReducer = (state = initialUserState, action: Action): EwUserDetail => {
    switch (action.type) {
        case 'sandbox/user/getUser':
            return state;
        case 'sandbox/user/setUser':
            return produce(state, draft => {
                Object.assign(draft, action.payload);
            });
        case 'sandbox/user/setLocale':
            return produce(state, draft => {
                draft.localeKey = action.payload.localeKey;
            })
        default:
            return state;
    }
};

export default UserReducer;