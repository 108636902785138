import {
    GET_MEAL_FILTER_DATA_FAILED,
    GET_MEAL_FILTER_DATA_INITIALIZE,
    GET_MEAL_FILTER_DATA_SUCCESS,
    GET_GLOBAL_SETTING_FAILED,
    GET_LOOKUP_DATA_INITIALIZE,
    GET_LOOKUP_DATA_SUCCESS,
    GET_SERVING_FAILED,
    GET_SERVING_INITIALIZE,
    GET_SERVING_SUCCESS,
    RECIPE_SEARCH_FAILED,
    RECIPE_SEARCH_INITIALIZE,
    RECIPE_SEARCH_SUCCESS,
} from "../constants";

const initialState = {
    lookupContent: [],
    servingList: [],
    mealFilterData: {},
    searchResults: [],
    loading: false,
};

const CommonReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_LOOKUP_DATA_INITIALIZE:
            return { ...state, loading: true, lookupContent: [] };
        case GET_LOOKUP_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                lookupContent: action.payload.content,
            };
        case GET_GLOBAL_SETTING_FAILED:
            return { ...state, loading: false, lookupContent: [] };
        case GET_SERVING_INITIALIZE:
            return { ...state, loading: true, servingList: [] };
        case GET_SERVING_SUCCESS:
            return { ...state, loading: false, servingList: action.payload };
        case GET_SERVING_FAILED:
            return { ...state, loading: false, servingList: [] };
        case RECIPE_SEARCH_INITIALIZE:
            return { ...state, loading: true, searchResults: [] };
        case RECIPE_SEARCH_SUCCESS:
            return { ...state, loading: false, searchResults: action.payload };
        case RECIPE_SEARCH_FAILED:
            return { ...state, loading: false, searchResults: [] };
        case GET_MEAL_FILTER_DATA_INITIALIZE:
            return { ...state, loading: true, mealFilterData: [] };
        case GET_MEAL_FILTER_DATA_SUCCESS:
            return { ...state, loading: false, mealFilterData: action.payload };
        case GET_MEAL_FILTER_DATA_FAILED:
            return { ...state, loading: false, mealFilterData: [] };
        default:
            return { ...state };
    }
};

export default CommonReducer;
