import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import Reducers from "./Reducers";

export function configureStore() {
    const Store = createStore(
        Reducers,
        composeWithDevTools(applyMiddleware(thunk)),
    );
    return Store;
}
