import { combineReducers } from "redux";
import settings from "./settings/Reducer";
import occupationalinjury from "./occupationalinjury/Reducer";
import commonReducer from "./Reducer";
import AuthReducer from "./modules/auth";
import UserReducer from "./modules/user";
import BusyIndicatorReducer from "./modules/busy";
import NormalLoginReducer from "./normal-login/Reducer";
import CommonReducer from "./common/Reducer";
import MealPlanReducer from "./meal-plan/Reducer";
import UserDataReducer from "./user-data/Reducers";
import AssessmentDetailsReducer from "./assessment/Reducer";
import BadgeDetailsReducer from "./sidebar/Reducer";

const Reducers = combineReducers({
    settings,
    occupationalinjury,
    commonReducer,
    auth: AuthReducer,
    user: UserReducer,
    busy: BusyIndicatorReducer,
    normalLogin: NormalLoginReducer,
    common: CommonReducer,
    mealPlan: MealPlanReducer,
    userDetails: UserDataReducer,
    assessmentDetails: AssessmentDetailsReducer,
    sidebarBadgeDetails: BadgeDetailsReducer as any,
});

export default Reducers;
export type ApplicationState = ReturnType<typeof Reducers>;
