import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import "./assets/scss/style.scss";
import "./assets/icon/styles.css";
import Spinner from "./views/spinner/Spinner";
import * as serviceWorker from "./serviceWorker";
import Axios from "axios";
import { Provider } from "react-redux";
import { configureStore } from "./redux/Store";
import "react-loading-skeleton/dist/skeleton.css";
import "./assets/icon/styles.css";
import { SnackbarContextProvider } from "./layouts/layout-components/Snackbar/useSnackbar";
import "flatpickr/dist/themes/material_green.css";
import "./layouts/FullLayoutStyles.scss";
import { ThemeProvider } from "@mui/material";
import { PrimaryTheme } from "./PrimaryTheme";

const App = lazy(() => import("./App"));

Axios.defaults.headers.common["Access-Control-Allow-Origin"] =
    window?.location?.origin ?? "*";

ReactDOM.render(
    <ThemeProvider theme={PrimaryTheme}>
        <Provider store={configureStore()}>
            <SnackbarContextProvider>
                <Suspense fallback={<Spinner />}>
                    <App />
                </Suspense>
            </SnackbarContextProvider>
        </Provider>
    </ThemeProvider>,
    document.getElementById("root"),
);

serviceWorker.unregister();
