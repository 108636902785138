import {
    CREATE_MEAL_PLANS_FAILED,
    CREATE_MEAL_PLANS_INITIALIZE,
    CREATE_MEAL_PLANS_SUCCESS,
    GET_MEAL_PLANS_FAILED,
    GET_MEAL_PLANS_INITIALIZE,
    GET_MEAL_PLANS_SUCCESS,
} from "../constants";

const initialState = {
    loading: false,
    success: false,
    message: "",
    mealPlanList: [],
};

const MealPlanReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_MEAL_PLANS_INITIALIZE:
            return {
                ...state,
                loading: true,
                mealPlanList: [],
            };
        case GET_MEAL_PLANS_SUCCESS:
            return {
                ...state,
                loading: false,
                mealPlanList: action.payload,
            };
        case GET_MEAL_PLANS_FAILED:
            return {
                ...state,
                loading: false,
                mealPlanList: [],
            };
        case CREATE_MEAL_PLANS_INITIALIZE:
            return {
                ...state,
                loading: true,
                message: "",
                success: false,
            };
        case CREATE_MEAL_PLANS_SUCCESS:
            return {
                ...state,
                loading: false,
                message: "success",
                success: true,
            };
        case CREATE_MEAL_PLANS_FAILED:
            return {
                ...state,
                loading: false,
                message: action.payload.message,
                success: false,
            };
        default:
            return {
                ...state,
            };
    }
};

export default MealPlanReducer;
