import { getUserDetails } from "../../components/EqPolicy";
import {
    GET_GLOBAL_SETTING_FAILED,
    GET_GLOBAL_SETTING_INITIALIZE,
    GET_GLOBAL_SETTING_SUCCESS,
    NORMAL_LOGIN_FAILED,
    NORMAL_LOGIN_INITIALIZE,
    NORMAL_LOGIN_SUCCESS,
    NORMAL_LOGOUT,
    USER_LOGIN_FAILED,
} from "../constants";

const initialState = {
    loading: false,
    isAuthenticated: localStorage.getItem("isAuthenticated") === "true",
    userDetails: getUserDetails(),
    token: localStorage.getItem("token"),
    error: null,
    patientLabel: "",
};

const NormalLoginReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case NORMAL_LOGIN_INITIALIZE:
            return {
                ...state,
                loading: true,
                isAuthenticated: false,
                userDetails: null,
                error: null,
            };
        case NORMAL_LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                token: action.payload?.data.token,
                userDetails: action.payload?.data?.userDetail,
                error: null,
            };
        case NORMAL_LOGIN_FAILED:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                userDetails: null,
                error: action.payload,
            };
        case NORMAL_LOGOUT:
            return {
                ...state,
                isAuthenticated: false,
                token: "",
                userDetails: null,
            };
        case GET_GLOBAL_SETTING_INITIALIZE:
            return {
                ...state,
                loading: true,
                patientLabel: "",
                error: null,
            };
        case GET_GLOBAL_SETTING_SUCCESS:
            return {
                ...state,
                loading: false,
                patientLabel: action.payload.data.value,
                error: null,
            };
        case GET_GLOBAL_SETTING_FAILED:
            return {
                ...state,
                loading: false,
                patientLabel: "",
                error: null,
            };
        case USER_LOGIN_FAILED:
            return {
                ...state,
                loading: false,
                patientLabel: "",
                isAuthenticated: false,
                error: action.payload,
            };
        default:
            return {
                ...state,
            };
    }
};

export default NormalLoginReducer;
