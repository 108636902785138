import {
    FETCH_NATURE_OF_INJURY_VALUE,
    FETCH_SOURCE_OF_INJURY_VALUE,
    FETCH_BODY_AFFECTED_VALUE,
    FETCH_EXPOSURE_VALUE,
    FETCH_NATURE_OF_INJURY_ID,
    FETCH_SOURCE_OF_INJURY_ID,
    FETCH_BODY_AFFECTED_ID,
    FETCH_EXPOSURE_ID,
    FETCH_OCCUPATIONAL_NOTES,
    FETCH_OCCUPATIONAL_LOCATION,
    CLEAR_STORE,
} from "../constants/";

const INIT_STATE = {
    natureOfInjuryValue: "",
    sourceOfInjuryValue: "",
    bodyAffectedValue: "",
    exposureValue: "",
    natureOfInjuryId: 0,
    sourceOfInjuryId: 0,
    bodyAffectedId: 0,
    exposureId: 0,
    occupationalNotesvalue: "",
    occupationalLocationValue: "",
};

export default (state = INIT_STATE, action: { type: any; payload: any }) => {
    switch (action.type) {
        case FETCH_NATURE_OF_INJURY_VALUE:
            return {
                ...state,
                natureOfInjuryValue: action.payload,
            };
        case FETCH_SOURCE_OF_INJURY_VALUE:
            return {
                ...state,
                sourceOfInjuryValue: action.payload,
            };
        case FETCH_BODY_AFFECTED_VALUE:
            return {
                ...state,
                bodyAffectedValue: action.payload,
            };
        case FETCH_EXPOSURE_VALUE:
            return {
                ...state,
                exposureValue: action.payload,
            };
        case FETCH_NATURE_OF_INJURY_ID:
            return {
                ...state,
                natureOfInjuryId: action.payload,
            };
        case FETCH_SOURCE_OF_INJURY_ID:
            return {
                ...state,
                sourceOfInjuryId: action.payload,
            };
        case FETCH_BODY_AFFECTED_ID:
            return {
                ...state,
                bodyAffectedId: action.payload,
            };
        case FETCH_EXPOSURE_ID:
            return {
                ...state,
                exposureId: action.payload,
            };
        case FETCH_OCCUPATIONAL_NOTES:
            return {
                ...state,
                occupationalNotesvalue: action.payload,
            };
        case FETCH_OCCUPATIONAL_LOCATION:
            return {
                ...state,
                occupationalLocationValue: action.payload,
            };
        case CLEAR_STORE:
            return INIT_STATE;
        default:
            return state;
    }
};
