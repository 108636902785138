import { SET_UPLOADED_PRESCRIPTION_BADGE_COUNT } from "../constants";

const BadgeDetailsReducer = (state = [] as any, { type, payload }) => {
    switch (type) {
        case SET_UPLOADED_PRESCRIPTION_BADGE_COUNT:
            return [payload];
        default:
            return state;
    }
};

export default BadgeDetailsReducer;
