import {
    GET_USER_DATA_INITIALIZE,
    GET_USER_DATA_SUCCESS,
    GET_USER_DATA_FAILED,
} from "../constants";

const initialState = {
    loading: false,
    success: false,
    userData: null,
};

const UserDataReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_USER_DATA_INITIALIZE:
            return {
                ...state,
                loading: true,
                userData: null,
            };
        case GET_USER_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                userData: action.payload,
            };
        case GET_USER_DATA_FAILED:
            return {
                ...state,
                loading: false,
                userData: null,
            };
        default:
            return {
                ...state,
            };
    }
};

export default UserDataReducer;
